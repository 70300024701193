<template>
  <v-btn v-bind="$attrs" @click="click">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
  },
  methods: {
    click(event) {
      this.$confirm({
        title: this.title,
        text: this.text,
        confirm: {
          action: () => {
            this.$emit('confirm', event);
          },
        },
        cancel: {
          action: () => {
            this.$emit('cancel', event);
          },
        },
      });
    }
  }
}
</script>
