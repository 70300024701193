import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import lodash from 'lodash'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('America/Recife')

import { v4 as uuid } from 'uuid'

const utils = {
  ...lodash,
  moment: dayjs,
  uuid() {
    return uuid()
  },
  compare(first, second) {
    return first ? utils.toLower(utils.trim(first)).search(utils.toLower(utils.trim(second))) > -1 : true
  },
  search(list, property, compare) {
    return utils.filter(list, (item) => {
      return compare ? utils.toLower(item[property]).search(utils.toLower(compare)) > -1 : true
    })
  },
  startMonth() {
    return dayjs().startOf('month')
  },
  endMonth() {
    return dayjs().endOf('month')
  },
  now() {
    return dayjs()
  },
  date(date) {
    return dayjs(date)
  },
  none(items, predicate) {
    return !this.some(items, predicate)
  },
  isEmptyOrNone(items, predicate) {
    return this.isEmpty(items) || this.none(items, predicate)
  },
  removeBy(array, key, value) {
    return array.filter((item) => {
      return item[key] !== value
    })
  },
  push(array, value) {
    array.push(value)
  },
  unpush(array, value) {
    utils.remove(array, value)
  },
  extension(name) {
    return utils.chain(name).split('.').reverse().first().value().toUpperCase()
  },
  dateBetween(date, period) {
    if (utils.isEmpty(date) || utils.isEmpty(period)) return true

    return dayjs(date).isBetween(dayjs(period[0]), dayjs(period[1]), 'days', '[]')
  },
  dateFormat(date, format = 'DD/MM/YYYY') {
    return utils.date(date).format(format)
  },
  dateEurope(date) {
    return utils.date(date).format('DD/MM/YYYY')
  },
  hourShort: (date) => {
    return utils.date(date).format('HH:mm')
  },
  timeBetween(first, second) {
    // const minutes = utils.date(second).diff(first, 'minutes');

    // if (minutes > 0) {
    //   return [minutes, minutes > 1 ? 'm' : 'dia'].join(' ');
    // }

    // const hours = utils.date(second).diff(first, 'hours');

    // if (hours > 0) {
    //   return [hours, hours > 1 ? 'hs' : 'h'].join(' ');
    // }

    const minutes = utils.date(second).diff(first, 'minutes')

    if (minutes > 0) {
      return [minutes, 'min'].join(' ')
    }

    const seconds = utils.date(second).diff(first, 'seconds')

    return [seconds, 'seg'].join(' ')
  },
  initials(name) {
    const words = utils.words(name)

    if (words.length > 1) {
      return words[0].substr(0, 1).concat(words[1].substr(0, 1)).toUpperCase()
    }

    return words[0].substr(0, 2).toUpperCase()
  },
  groupByDate(list) {
    return utils.groupBy(list, (item) => {
      return utils.date(item.created).format('YYYY-MM-DD')
    })
  },
  rangeShuffle(program, normal) {
    return utils.range(program.attempts).reduce((acc) => {
      return { ...acc, ...(program.randomize ? utils.shuffle(normal) : normal) }
    })
  },
  stimulusType(program, type) {
    return program.stimulus.map((stimulu) => ({
      ...stimulu,
      type,
      status: null,
    }))
  },
  upset(first, second) {
    return utils.chain().unionBy([first], second, 'id').value()
  },
  insert(list, data) {
    list.push(data)
  },
  update(list, data) {
    list.forEach((item) => {
      if (item.id == data.id) {
        Object.assign(item, data)
      }
    })
  },
  applications(program) {
    const { stimulus, attempts, probe, randomize, opportunity } = program

    let probesStart = []
    let probesEnd = []

    if (probe) {
      probesStart = this.mapStimulus(stimulus, 2)
      probesEnd = this.mapStimulus(stimulus, 2)
    }

    return [
      ...probesStart,
      ...utils
        .chain()
        .range(attempts)
        .map(() => {
          const items = this.mapStimulus(stimulus, opportunity ? 3 : 1)
          return randomize ? utils.shuffle(items) : items
        })
        .flatten()
        .value(),
      ...probesEnd,
    ]
  },
  mapStimulus(items, type = 1) {
    return [
      ...items.map((item) => ({
        ...item,
        type,
        status: null,
      })),
    ]
  },
}

export default utils
