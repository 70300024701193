<template>
  <v-btn v-bind="$attrs" @click="click">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  props: {
    name: String,
    query: Object,
  },
  methods: {
    click() {
      this.$to(this.name, this.query);
    }
  }
}
</script>
