<template>
  <svg class="mt-1 grey--text" width="24" height="24" viewBox="0 0 513 513" xmlns="http://www.w3.org/2000/svg">
    <path d="M408.272 106.256L408.462 106.473L408.656 106.685C419.607 118.656 421.334 128.307 421.334 134.667V170.601H446.334V134.667C446.334 122.243 442.39 106.522 427.102 89.8106C410.472 70.7715 401.789 61.6678 388.874 48.1287L388.871 48.1252C382.083 41.0095 374.127 32.6685 363.235 21.0163C349.726 7.50764 330.297 0 315.696 0H108.878C85.1302 0 65.8785 19.2518 65.8785 43V170.601H90.8785V43C90.8785 33.0589 98.9374 25 108.878 25H315.696C323.238 25 336.227 29.5281 345.294 38.4329C356.421 50.3333 364.407 58.7038 371.137 65.757C383.784 79.0131 391.992 87.6167 408.272 106.256Z" fill="currentColor"/>
    <path d="M90.8785 390.183H65.8785L65.8785 469C65.8785 492.748 85.1303 512 108.879 512H403.334C427.082 512 446.334 492.748 446.334 469V390.183H421.334V469C421.334 478.941 413.275 487 403.334 487H108.879C98.9374 487 90.8785 478.941 90.8785 469V390.183Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M457.26 170.723H55.74C44.6943 170.723 35.74 179.677 35.74 190.723V370.114C35.74 381.16 44.6943 390.114 55.74 390.114H457.26C468.306 390.114 477.26 381.16 477.26 370.114V190.723C477.26 179.677 468.306 170.723 457.26 170.723ZM55.74 145.723C30.8872 145.723 10.74 165.87 10.74 190.723V370.114C10.74 394.967 30.8872 415.114 55.74 415.114H457.26C482.113 415.114 502.26 394.967 502.26 370.114V190.723C502.26 165.87 482.113 145.723 457.26 145.723H55.74Z" fill="currentColor"/>
    <text fill="currentColor" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="200" font-weight="900" letter-spacing="0em">
      <tspan x="30" y="355">
        {{ $utils.extension(name) }}
      </tspan>
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
  },
};
</script>
