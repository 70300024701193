import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import utils from '@/services/utils'
import http from '@/services/http'

Vue.use(VueRouter)

const beforeEnter = (_, __, next) => {
  if (utils.get(store, 'state.auth.token', false)) {
    return next()
  }
  window.location.href = 'auth/login'
}

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Internal'),
    beforeEnter,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/pages/Home'),
      },
      // profile
      {
        path: '/perfil',
        name: 'profile',
        component: () => import('@/pages/Profile'),
      },
      // coordinators
      {
        path: '/coordenadores',
        name: 'coordinators',
        component: () => import('@/pages/Coordinators'),
      },
      {
        path: '/coordenadores/registro',
        name: 'coordinators.register',
        component: () => import('@/pages/Coordinators/Register'),
        props: ({ query }) => ({
          id: utils.toInteger(query.id),
        }),
      },
      // applicators
      {
        path: '/aplicadores',
        name: 'applicators',
        component: () => import('@/pages/Applicators'),
      },
      {
        path: '/aplicadores/registro',
        name: 'applicators.register',
        component: () => import('@/pages/Applicators/Register'),
        props: ({ query }) => ({
          id: utils.toInteger(query.id),
        }),
      },
      // collaborators
      {
        path: '/equipe',
        name: 'collaborators',
        component: () => import('@/pages/Collaborators'),
      },
      {
        path: '/equipe/registro',
        name: 'collaborators.register',
        component: () => import('@/pages/Collaborators/Register'),
        props: ({ query }) => ({
          id: utils.toInteger(query.id),
        }),
      },
      // companions
      {
        path: '/acompanhantes',
        name: 'companions',
        component: () => import('@/pages/Companions'),
      },
      {
        path: '/acompanhantes/registro',
        name: 'companions.register',
        component: () => import('@/pages/Companions/Register'),
        props: ({ query }) => ({
          id: utils.toInteger(query.id),
        }),
      },
      // students
      {
        path: '/estudantes',
        name: 'students',
        component: () => import('@/pages/Students'),
      },
      {
        path: '/estudantes/registro',
        name: 'students.register',
        component: () => import('@/pages/Students/Register'),
        props: ({ query }) => ({
          id: utils.toInteger(query.id),
        }),
      },
      // student
      {
        path: '/estudante/:studentId',
        name: 'student',
        component: () => import('@/pages/Student'),
        redirect: {
          name: 'student.programs',
        },
        props: (route) => ({
          studentId: route.params.studentId,
        }),
        children: [
          // attendances
          {
            path: '/estudante/:studentId/atendimentos',
            name: 'student.attendances',
            component: () => import('@/pages/Student/Attendances'),
          },
          {
            path: '/estudante/:studentId/atendimentos/registro',
            name: 'student.attendances.register',
            component: () => import('@/pages/Student/Attendances/Register'),
          },
          {
            path: '/estudante/:studentId/atendimentos/:attendanceId/visualizar',
            name: 'student.attendances.view',
            component: () => import('@/pages/Student/Attendances/View'),
          },
          // programs
          {
            path: '/estudante/:studentId/programas',
            name: 'student.programs',
            component: () => import('@/pages/Student/Programs'),
          },
          {
            path: '/estudante/:studentId/programas/cadastrar',
            name: 'student.programs.register',
            component: () => import('@/pages/Student/Programs/Register'),
          },
          {
            path: '/estudante/:studentId/programas/:programId/editar',
            name: 'student.programs.edit',
            component: () => import('@/pages/Student/Programs/Edit'),
          },
          // program supervisions
          {
            path: '/estudante/:studentId/programas/:programId/supervisoes',
            name: 'student.programs.supervisions',
            component: () => import('@/pages/Student/Programs/Supervisions'),
          },
          {
            path: '/estudante/:studentId/programas/:programId/supervisoes/cadastrar',
            name: 'student.programs.supervisions.register',
            component: () => import('@/pages/Student/Programs/Supervisions/Register'),
          },
          {
            path: '/estudante/:studentId/programas/:programId/supervisoes/:supervisionId/visualizar',
            name: 'student.programs.supervisions.view',
            component: () => import('@/pages/Student/Programs/Supervisions/View'),
          },
          // program graphic
          {
            path: '/estudante/:studentId/programas/:programId/grafico',
            name: 'student.programs.graphic',
            component: () => import('@/pages/Student/Programs/Graphic'),
          },
          // reports
          {
            path: '/estudante/:studentId/relatos',
            name: 'student.reports',
            component: () => import('@/pages/Student/Reports'),
          },
          {
            path: '/estudante/:studentId/relatos/cadastrar',
            name: 'student.reports.register',
            component: () => import('@/pages/Student/Reports/Register'),
          },
          {
            path: '/estudante/:studentId/relatos/:reportId/editar',
            name: 'student.reports.edit',
            component: () => import('@/pages/Student/Reports/Edit'),
          },
          // files
          {
            path: '/estudante/:studentId/arquivos',
            name: 'student.files',
            component: () => import('@/pages/Student/Files'),
          },
          {
            path: '/estudante/:studentId/arquivos/cadastrar',
            name: 'student.files.register',
            component: () => import('@/pages/Student/Files/Register'),
          },
          {
            path: '/estudante/:studentId/arquivos/:fileId/editar',
            name: 'student.files.edit',
            component: () => import('@/pages/Student/Files/Edit'),
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/layouts/External'),
    children: [
      {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('@/pages/Login'),
      },
      {
        path: '/auth/recover',
        name: 'auth.recover',
        component: () => import('@/pages/Recover'),
      },
      {
        path: '/auth/reset',
        name: 'auth.reset',
        component: () => import('@/pages/Reset'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.params.studentId) {
    http.defaults.headers.common['X-Student-Id'] = to.params.studentId
  } else {
    delete http.defaults.headers.common['X-Student-Id']
  }

  next()
})

export default router
