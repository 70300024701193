<template>
  <v-file-input outlined dense hide-details clearable prepend-inner-icon="mdi-paperclip" :prepend-icon="null" :loading="loading" v-bind="$attrs" @change="choose"/>
</template>

<script>
  const DefaultData = () => ({
    loading: false,
    disabled: false,
  });

  export default {
    data: DefaultData,
    watch: {
      loading(value) {
        this.disabled = value;
      }
    },
    methods: {
      async choose(file) {
        this.$emit('choose', file);

        if (file) {
          this.loading = true;

          try {
            await this.$service.upload.file(file).then((data) => {
              this.$emit('success', data);
            });
          } catch (error) {
            this.$emit('error', error);
          }

          this.loading = false;
        }
      }
    }
}
</script>