import http from './http';

const list = (params) => {
  return http.get('student/reports', {params});
};

const save = (data) => {
  return data.id ? update(data) : create(data);
};

const create = (data) => {
  return http.post('student/reports', data);
};

const update = (data) => {
  return http.put('student/reports', data);
};

const trash = (params) => {
  return http.delete('student/reports', {params});
};

export default { list, save, create, update, trash };