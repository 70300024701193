import Vue from 'vue';

import AppBar from '@/components/AppBar';
import Base from '@/components/Base';
import AppBarHead from '@/components/AppBarHead';
import Avatar from '@/components/Avatar';
import Dialog from '@/components/Dialog';
import ErrorIcon from '@/components/ErrorIcon';
import Label from '@/components/Label';
import Loading from '@/components/Loading';
import Menu from '@/components/Menu';
import TabList from '@/components/TabList';
import Lists from '@/components/Lists';
import AbaSnackebar from '@/components/Snackebar';
import AbaConfirm from '@/components/Confirm';
import Bodie from '@/components/Bodie';
import ExtensionIcon from '@/components/ExtensionIcon';
import ErrorHelpIcon from '@/components/ErrorHelpIcon';
import ErrorOnlyIcon from '@/components/ErrorOnlyIcon';
import HitHelpIcon from '@/components/HitHelpIcon';
import HitOnlyIcon from '@/components/HitOnlyIcon';
import PortalAppBar from '@/components/PortalAppBar';
import PortalAppFooter from '@/components/PortalAppFooter';
import AvatarUploader from '@/components/AvatarUploader';
import AttentionCard from '@/components/AttentionCard';
import InputFilter from '@/components/InputFilter';
import InputText from '@/components/InputText';
import InputPassword from '@/components/InputPassword';
import InputNumber from '@/components/InputNumber';
import BtnConfirm from '@/components/BtnConfirm';
import BtnTo from '@/components/BtnTo';
import BtnIcon from '@/components/BtnIcon';
import StudentsSelect from '@/components/StudentsSelect';
import Pic from '@/components/Pic';
import OverlayLoader from '@/components/OverlayLoader';
import Policy from '@/components/Policy';
import FileUploader from '@/components/FileUploader';
import CardLoading from '@/components/CardLoading';
import Trashing from '@/components/Trashing';
import InputError from '@/components/InputError';
import StatusIcon from '@/components/StatusIcon';

Vue.component('AbaAppBar', AppBar);
Vue.component('AbaBase', Base);
Vue.component('AbaAppBarHead', AppBarHead);
Vue.component('AbaAvatar', Avatar);
Vue.component('AbaDialog', Dialog);
Vue.component('AbaIconError', ErrorIcon);
Vue.component('AbaLabelField', Label);
Vue.component('AbaLoading', Loading);
Vue.component('AbaMenu', Menu);
Vue.component('AbaTabList', TabList);
Vue.component('AbaLists', Lists);
Vue.component('AbaSnackebar', AbaSnackebar);
Vue.component('AbaConfirm', AbaConfirm);
Vue.component('AbaExtensionIcon', ExtensionIcon);
Vue.component('ErrorHelpIcon', ErrorHelpIcon);
Vue.component('ErrorOnlyIcon', ErrorOnlyIcon);
Vue.component('HitHelpIcon', HitHelpIcon);
Vue.component('HitOnlyIcon', HitOnlyIcon);
Vue.component('Bodie', Bodie);
Vue.component('InputNumber', InputNumber);
Vue.component('PortalAppBar', PortalAppBar);
Vue.component('PortalAppFooter', PortalAppFooter);
Vue.component('AvatarUploader', AvatarUploader);
Vue.component('AttentionCard', AttentionCard);
Vue.component('InputFilter', InputFilter);
Vue.component('InputText', InputText);
Vue.component('InputPassword', InputPassword);
Vue.component('BtnConfirm', BtnConfirm);
Vue.component('BtnTo', BtnTo);
Vue.component('BtnIcon', BtnIcon);
Vue.component('StudentsSelect', StudentsSelect);
Vue.component('Pic', Pic);
Vue.component('OverlayLoader', OverlayLoader);
Vue.component('Policy', Policy);
Vue.component('FileUploader', FileUploader);
Vue.component('CardLoading', CardLoading);
Vue.component('Trashing', Trashing);
Vue.component('InputError', InputError);
Vue.component('StatusIcon', StatusIcon);
