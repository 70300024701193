import http from './http';
import utils from './utils';
import auth from './auth';
import file from './file';
import attendance from './attendance';
import program from './program';
import report from './report';
import resource from './resource';
import student from './student';
import upload from './upload';
import supervision from './supervision';
import profile from './profile';

export default { http, utils, auth, file, attendance, program, report, resource, student, upload, supervision, profile };
