<template>
  <v-menu>
    <template v-slot:activator="{ on }">
      <v-btn depressed block tile color="primary" v-on="on">
        <slot v-bind:text="text">
          {{ text }}
        </slot>
      </v-btn>
    </template>
    <v-list>
      <template v-for="(item, index) in items">
        <v-list-item :key="item" @click="select(index)">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    items: {
      type: Array,
      require: true,
    }
  },
  methods: {
    select(item) {
      this.$emit('input', item);
    }
  },
  computed: {
    text() {
      return this.items[this.value];
    }
  }
}
</script>