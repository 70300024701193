<template>
  <v-list class="aba-lists">
    <template v-for="(item, key) in items">
      <v-list-item :key="key">
        <template v-if="hasLeft">
          <slot name="left" :item="item"/>
        </template>
        <v-list-item-content>
          <slot :item="item"/>
        </v-list-item-content>
        <template v-if="hasRight">
          <slot name="right" :item="item"/>
        </template>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      require: true,
    },
  },
  computed: {
    hasLeft() {
      return !!this.$slots.left;
    },
    hasRight() {
      return !!this.$slots.right;
    },
  },
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .aba-lists {
    padding: 0;

    > * + * {
      border-top: 1px solid map-get($grey, 'lighten-2');
    }
  }
</style>