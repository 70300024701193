// import StorageService from '../services/storage';

import utils from '@/services/utils';

export default {
  // getStoreAuth: (state) => {
  //   return state.auth || StorageService.get('auth')
  // },
  // profile: (state) => {
  //   const auth = state.auth || StorageService.get('auth')
    
  //   return auth.user.profile
  // },

  auth({auth}) {
    return auth;
  },
  user({auth: {user}}) {
    return user;
  },
  profile({auth: {user: {profile}}}) {
    return profile;
  },
  role({auth: {user: {profile: {role}}}}) {
    return role;
  },
  fases({fases}) {
    return fases;
  },
  helps({helps}) {
    return helps;
  },
  places({places}) {
    return places;
  },

  // student({student}) {
  //   return student;
  // },
  // programs({student: {programs}}) {
  //   return programs;
  // },
  // attendances({student: {attendances}}) {
  //   return attendances;
  // },
  // reports({student: {reports}}) {
  //   return reports;
  // },
  // files({student: {files}}) {
  //   return files;
  // },
  // attendance(state) {
  //   return state.attendances[state.student.id] || null;
  // },
  // studentReports({student: {reports}}) {
  //   return utils.groupBy(reports, report => {
  //     return utils.date(report.created).format('YYYY-MM-DD');
  //   });
  // },
  // studentAttendances({student: {attendances}}) {
  //   return utils.groupBy(attendances, attendance => {
  //     return utils.date(attendance.end).format('YYYY-MM-DD');
  //   });
  // },
}