<template>
  <v-tab-item class="aba-tab-list">
    <v-list class="aba-tab-list">
      <template v-for="(item, key) in items">
        <v-list-item :key="`item-${key}`">
          <v-list-item-content>
            <slot name="content" :item="item"/>
          </v-list-item-content>
          <template v-if="hasAction">
            <v-list-item-action>
              <slot name="action" :item="item" />
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-divider :key="`divider-${key}`"/>
      </template>
    </v-list>
  </v-tab-item>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      require: true,
    },
  },
  computed: {
    hasAction() {
      return !!this.$slots.action;
    }
  }
}
</script>

<style lang="scss">
  .aba-tab-list {
    .v-list {
      padding: 0;
    }
  }
</style>