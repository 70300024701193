import FileDownload from 'js-file-download';

import http from './http';

const list = (params) => {
  return http.get('student/files', {params});
};

const save = (data) => {
  return data.id ? update(data) : create(data);
}

const create = (data) => {
  return http.post('student/files', data);
};

const update = (data) => {
  return http.put('student/files', data);
};

const trash = (params) => {
  return http.delete('student/files', {params});
};

const download = (file) => {
  return http.get(file.url, {
    responseType: 'blob'
  }).then(response => {
    FileDownload(response, file.name);
  });
};

export default { list, save, create, update, trash, download };