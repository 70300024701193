<template>
  <v-snackbar v-model="open">
    {{ text }}
    <template v-slot:action>
      <v-btn text :color="color" @click="open = false">OK</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      text: '',
      color: 'green',
    }
  },
  mounted() {
    this.$root.$on('snackbar', (data) => {
      this.open = this.get(data, 'open', true);
      this.text = this.get(data, 'text', null);
      this.color = this.get(data, 'color', 'green');
    });
  },
  methods: {
    get(data, item, defaults) {
      return this.$utils.get(data, item, defaults);
    }
  }
}
</script>