<template>
  <v-dialog scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition" :value="value">
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <template v-if="hasLeft">
            <slot name="left"/>
          </template>
          <v-spacer/>
          <template v-if="hasHeader">
            <slot name="header"/>
          </template>
          <v-spacer/>
          <template v-if="hasRight">
            <slot name="right"/>
          </template>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <slot/>
      </v-card-text>
      <template v-if="hasActions">
        <v-card-actions class="tertiary">
          <slot name="actions"/>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHeader() {
      return !!this.$slots.header;
    },
    hasLeft() {
      return !!this.$slots.left;
    },
    hasRight() {
      return !!this.$slots.right;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
  }
}
</script>