<template>
  <v-list-item :class="[align]">
    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle class="font-weight-regular">
        {{ caption }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    caption: {
      type: String,
    },
    align: {
      type: String,
      default: 'text-center',
    }
  }
}
</script>