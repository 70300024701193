<template>
  <v-main>
    <template v-if="loading">
      <v-overlay color="white">
        <v-progress-circular indeterminate color="primary" size="32"/>
      </v-overlay>
    </template>
    <template v-else>
      <slot/>
    </template>
  </v-main>
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
}
</script>
