<template>
  <fragment>
    <template v-if="inRoles || isOwner">
      <slot />
    </template>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'

export default {
  components: {
    Fragment,
  },
  props: {
    roles: {
      default: () => {
        return ['supervisor', 'coordinator']
      },
    },
    owner: {
      default: () => {
        return {}
      },
    },
  },
  computed: {
    inRoles() {
      return this.roles.includes(this.auth.user.role)
    },
    isOwner() {
      return this.owner.id ? this.owner.id == this.auth.user.id : true
    },
  },
}
</script>
