import axios from 'axios';

import store from '@/store';
import utils from '@/services/utils';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(
  (config) => {
    const token = utils.get(store, 'state.auth.token', false);

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export default http;
