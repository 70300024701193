<template>
  <fragment>
    <template v-if="value">
      <v-layout align-center justify-center fill-height>
        <v-progress-circular indeterminate color="primary" />
      </v-layout>
    </template>
    <template v-else>
      <slot />
    </template>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
export default {
  components: {
    Fragment,
  },
  props: {
    value: Boolean,
  },
}
</script>
