import http from './http'

const welcome = () => {
  return http.get('/')
}

const login = (credentials) => {
  return http.post('login', credentials)
}

const logout = () => {
  return http.post('logout')
}

const recover = (email) => {
  return http.post('recover', { email })
}

const reset = (params) => {
  return http.post('reset', params)
}

export default { welcome, login, logout, recover, reset }
