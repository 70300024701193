import Vue from 'vue';

import services from '@/services';

import { mapState } from 'vuex';

Vue.mixin({
  methods: {
    $roles(...roles) {
      return this.auth.user && roles.includes(this.auth.user.role);
    },
    $owner(id) {
      return id ? this.auth.user.id === id : true;
    },
    $role(role) {
      return {
        supervisor: 'Supervisor',
        coordinator: 'Coordenador',
        applicator: 'Aplicador',
        collaborator: 'Equipe',
        companion: 'Acompanhante',
      }[role] || null;
    },
    $snackbar(data) {
      this.$root.$emit('snackbar', data);
    },
    $snackSuccess(text) {
      this.$snackbar({
        text, color: 'green',
      });
    },
    $snackWarn(text) {
      this.$snackbar({
        text, color: 'yellow',
      });
    },
    $snackError(text) {
      this.$snackbar({
        text, color: 'red',
      });
    },
    $confirm(data) {
      this.$root.$emit('confirm', data);
    },
    $drawer() {
      this.$root.$emit('drawer');
    },
    $to(name, query = {}) {
      this.$router.push({name, query});
    },
    $go(name, params) {
      this.$router.push({name, params});
    },
    $segment(index) {
      return this.$route.path.split('/')[index];
    },
    $try(promise) {
      return promise.then(result => [null, result]).catch(error => [error]);
    },
  },
  computed: {
    $service() {
      return services;
    },
    $utils() {
      return services.utils;
    },
    ...mapState({
      auth: state => state.auth,
      user: state => state.auth.user,
    }),
  },
  filters: {
    date(value) {
      return services.utils.dateFormat(value, 'DD/MM/YYYY');
    },
    hour(value) {
      return services.utils.dateFormat(value, 'HH:mm');
    },
  }
})