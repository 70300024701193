<template>
  <v-app-bar app short dark class="aba-app-bar" color="primary" elevation="0" height="56" :extended="false">
    <template v-if="hasLeft">
      <slot name="left"/>
    </template>
    <v-spacer/>
    <slot/>
    <v-spacer/>
    <template v-if="hasRight">
      <slot name="right"/>
    </template>
    <template v-slot:extension v-if="hasExtension">
      <slot name="extension"/>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    hasLeft() {
      return !!this.$slots.left;
    },
    hasRight() {
      return !!this.$slots.right;
    },
    hasExtension() {
      return !!this.$slots.extension;
    },
  }
}
</script>