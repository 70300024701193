<template>
  <v-img :src="src" />
</template>

<script>
import http from '@/services/http';

export default {
  props: {
    path: String,
  },
  data() {
    return {
      src: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      return http.request({ url: this.path, method: 'get', responseType: 'blob' }).then((blob) => {
        this.src = URL.createObjectURL(blob);
      });
    },
  },
};
</script>
