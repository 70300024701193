import http from './http';

export const view = () => {
  return http.get('student');
};

const list = (params) => {
  return http.get('students', { params });
};

const create = (data) => {
  return http.post('students', data);
};

const update = (data) => {
  return http.put('students', data);
};

const save = (data) => {
  return data.id ? update(data) : create(data);
};

const trash = (params) => {
  return http.delete('students', { params });
};

const attendance = (params) => {
  return http.get('students/attendances/view', { params });
};

export default { view, list, save, create, update, trash, attendance };
