<template>
  <v-card outlined>
    <v-list flat max-height="400" style="overflow: auto">
      <v-list-item-group multiple class="div-y" :value="value" @change="change">
        <template v-for="item in lists">
          <v-list-item :key="item.id" :value="item.id">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Array,
    lists: Array,
  },
  methods: {
    change(value) {
      this.$emit('input', value)
    },
  },
}
</script>
