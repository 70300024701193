<template>
  <svg :width="size" :height="size" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8851 8.02344C9.87038 9.3219 9.36304 11.0417 9.36304 13.1829V13.3635C9.36304 15.496 9.87038 17.2245 10.8851 18.5487C11.9084 19.873 13.2283 20.5351 14.845 20.5351C16.5476 20.5351 17.8761 19.9504 18.8306 18.7809L18.9467 20.2771H21.1395V0.464844H18.7532V7.73967C17.7987 6.62179 16.5046 6.06285 14.8708 6.06285C13.2283 6.06285 11.8998 6.71638 10.8851 8.02344ZM12.7296 17.1686C12.076 16.2743 11.7493 15.036 11.7493 13.4538C11.7493 11.6824 12.076 10.3452 12.7296 9.44229C13.3831 8.53938 14.2946 8.08793 15.4641 8.08793C16.9431 8.08793 18.0395 8.74576 18.7532 10.0614V16.472C18.0567 17.8307 16.9517 18.51 15.4383 18.51C14.286 18.51 13.3831 18.0629 12.7296 17.1686Z" :fill="fill"/>
    <path d="M0 14.3267H7.54919V12.3267H0V14.3267Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Boolean, Number],
      default: '21',
    },
    color: {
      type: [String, Boolean, Number],
      default: '#FFBD5C',
    }
  },
  computed: {
    fill() {
      return this.color ? this.color : '#FFBD5C';
    }
  }
}
</script>

