import utils from '@/services/utils';

export default {
  // StartAttendenceAction(context, payload) {
  //   context.commit('startAttendence', payload)
  // },
  // StopAttendenceAction(context) {
  //   context.commit('startAttendence')
  // },
  // StoreAuthAction: (context, payload) => {
  //   return context.commit('storeAuth', payload)
  // },
  // UpdateAuthAction: (context, payload) => {
  //   return context.commit('updateAuth', payload)
  // },
  // RemoveAuthAction: (context) => {
  //   return context.commit('removeAuth')
  // },
  // UpdateStudent: (context, payload) => {
  //   return context.commit('setStudent', payload);
  // },
  // UpdatePrograms: (context, payload) => {
  //   return context.commit('setPrograms', payload);
  // },
  // UpdateAttendances: (context, payload) => {
  //   return context.commit('setAtendances', payload);
  // },
  // UpdateReports: (context, payload) => {
  //   return context.commit('setReports', payload);
  // },
  // UpdateFiles: (context, payload) => {
  //   return context.commit('setFiles', payload);
  // },
  // UpdateFases: (context, payload) => {
  //   return context.commit('setFases', payload);
  // },
  // UpdateHelps: (context, payload) => {
  //   return context.commit('setHelps', payload);
  // },



  // getAuth({state}) {
  //   return state.auth;
  // },
  // getStudentFile({state}, id) {
  //   return new Promise((resolve) => {
  //     const file = utils.find(state.student.files, {id});
  //     if (file) {
  //       return resolve(file);
  //     }
  //   });
  // },
  // getStudentReport({state}, id) {
  //   return new Promise((resolve) => {
  //     const report = utils.find(state.student.reports, {id});
  //     if (report) {
  //       return resolve(report);
  //     }
  //   });
  // },
  // getStudentAttendance({state}, id) {
  //   return new Promise((resolve) => {
  //     const attendance = utils.find(state.student.attendances, {id});
  //     if (attendance) {
  //       return resolve(attendance);
  //     }
  //   });
  // },
  // getStudentAttendanceProgram({state}, id) {
  //   return new Promise((resolve) => {
  //     const program = utils.find(state.attendance[state].programs, {id});
  //     if (program) {
  //       return resolve(program);
  //     }
  //   });
  // }
  attendanceBy({state: attendances}, id) {
    return utils.find(attendances, {id});
  },
}