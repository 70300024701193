import http from './http';

const list = () => {
  return http.get('sources');
};

const students = () => {
  return http.get('sources/students');
};

export default { list, students };
