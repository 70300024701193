<template>
  <svg :width="size" :height="size" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5696 11.5H3.56958V9.5H17.5696V11.5Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Boolean, Number],
      default: '21',
    },
    color: {
      type: [String, Boolean, Number],
      default: '#F44336',
    }
  },
  computed: {
    fill() {
      return this.color ? this.color : '#F44336';
    }
  }
}
</script>

