<template>
  <v-row align="center" justify="center">
    <v-col cols="auto">
      <div class="tertiary rounded-circle">
        <v-avatar color="tertiary" size="76" class="ma-1">
          <template v-if="saving">
            <v-progress-circular indeterminate color="primary" />
          </template>
          <template v-else-if="value">
            <pic :path="value" />
          </template>
          <template v-else>
            <v-icon size="40">mdi-account-outline</v-icon>
          </template>
        </v-avatar>
      </div>
    </v-col>
    <v-col cols="auto">
      <v-btn
        depressed
        block
        rounded
        color="tertiary"
        :disabled="saving"
        @click="onOpen"
      >
        <input ref="input" type="file" class="d-none" @change="onChoose" />
        Alterar Foto
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    onOpen() {
      this.$refs.input.click();
    },
    async onChoose(event) {
      this.saving = true;
      this.$emit("input", null);

      await this.$service.upload.avatar(event.target.files).then((avatar) => {
        this.$emit("input", avatar.url);
      });

      this.saving = false;
      this.$refs.input.value = null;
    },
  },
};
</script>