<template>
  <v-text-field hide-details solo dense light clearable class="white" label="Pesquisar" :value="value" @input="input"/>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    }
  }
};
</script>