<template>
  <validation-provider :vid="name" :rules="rules" v-slot="{ validated, invalid, errors }">
    <template v-if="label">
      <label class="label" :class="{'red--text': validated && invalid}">
        {{ label }}
      </label>
    </template>
    <v-text-field dense outlined hide-details flat :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :error="validated && invalid" :value="value" @input="input" @click:append="toggle"/>
    <template v-if="validated && invalid">
      <small class="red--text">{{ errors[0] }}</small>
    </template>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    rules: String,
    label: String,
    name: String,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    toggle() {
      this.show = !this.show;
    }
  },
  computed: {},
}
</script>