<template>
  <v-btn icon @click="click">
    <v-icon>mdi-{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: String,
  },
  methods: {
    click(event) {
      this.$emit('click', event);
    }
  }
}
</script>
