import http from './http';

const list = (params) => {
  return http.get('student/program/supervisions', { params });
};

const create = (data) => {
  return http.post('student/program/supervisions', data);
};

const update = (data) => {
  return http.put('student/program/supervisions', data);
};

const trash = (params) => {
  return http.delete('student/program/supervisions', {params});
};

export default {
  list,
  create,
  update,
  trash,
};
