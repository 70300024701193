<template>
  <v-overlay absolute z-index="0" color="white" :opacity="0.9" :value="value">
    <v-progress-circular indeterminate color="primary" size="26" />
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: Boolean
  }
}
</script>