import http from './http'

const list = (params) => {
  return http.get('student/programs', { params })
}

const save = (data) => {
  return data.id ? update(data) : create(data)
}

const create = (data) => {
  return http.post('student/programs', data)
}

const update = (data) => {
  return http.put('student/programs', data)
}

const trash = (params) => {
  return http.delete('student/programs', { params })
}

const finish = (data) => {
  return http.post('student/programs/finish', data)
}

const unfinish = (data) => {
  return http.post('student/programs/unfinish', data)
}

const graphic = (params) => {
  return http.get('student/program/graphic', { params })
}

export default {
  list,
  save,
  create,
  update,
  trash,
  graphic,
  finish,
  unfinish,
}
