<template>
  <validation-provider :vid="name" :rules="rules" v-slot="{ validated, invalid, errors }">
    <template v-if="label">
      <label class="label" :class="{'red--text': validated && invalid}">
        {{ label }}
      </label>
    </template>
    <v-text-field dense outlined hide-details flat :error="validated && invalid" :value="value" @input="input" v-bind="$attrs"/>
    <template v-if="validated && invalid">
      <small class="red--text">{{ errors[0] }}</small>
    </template>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    rules: String,
    label: String,
    name: String,
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
  },
  computed: {},
}
</script>