import http from './http';

const create = (data) => {
  return http.post('student/attendances', data);
};

const trash = (params) => {
  return http.delete('student/attendances', { params });
};

const applications = (params) => {
  return http.get('student/attendance/applications', { params });
};

export default {
  create,
  trash,
  applications,
};
