<template>
  <svg :width="size" :height="size" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5698 18.5C6.15982 18.5 2.56982 14.91 2.56982 10.5C2.56982 6.09 6.15982 2.5 10.5698 2.5C14.9798 2.5 18.5698 6.09 18.5698 10.5C18.5698 14.91 14.9798 18.5 10.5698 18.5ZM10.5698 0.5C9.25661 0.5 7.95625 0.758658 6.74299 1.2612C5.52973 1.76375 4.42734 2.50035 3.49876 3.42893C1.62339 5.3043 0.569824 7.84784 0.569824 10.5C0.569824 13.1522 1.62339 15.6957 3.49876 17.5711C4.42734 18.4997 5.52973 19.2362 6.74299 19.7388C7.95625 20.2413 9.25661 20.5 10.5698 20.5C13.222 20.5 15.7655 19.4464 17.6409 17.5711C19.5163 15.6957 20.5698 13.1522 20.5698 10.5C20.5698 9.18678 20.3112 7.88642 19.8086 6.67317C19.3061 5.45991 18.5695 4.35752 17.6409 3.42893C16.7123 2.50035 15.6099 1.76375 14.3967 1.2612C13.1834 0.758658 11.883 0.5 10.5698 0.5V0.5ZM11.5698 5.5H9.56982V9.5H5.56982V11.5H9.56982V15.5H11.5698V11.5H15.5698V9.5H11.5698V5.5Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Boolean, Number],
      default: '21',
    },
    color: {
      type: [String, Boolean, Number],
      default: '#2F89E1',
    }
  },
  computed: {
    fill() {
      return this.color ? this.color : '#2F89E1';
    }
  }
}
</script>

