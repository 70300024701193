<template>
  <svg :class="classes" :width="size" :height="size" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <!-- 1 = error -->
    <template v-if="type == 1">
      <path d="M17.5696 11.5H3.56958V9.5H17.5696V11.5Z" fill="currentColor"/>
    </template>
    <!-- 2 = hit help -->
    <template v-if="type == 2">
      <path d="M17.5696 11.5H11.5696V17.5H9.56958V11.5H3.56958V9.5H9.56958V3.5H11.5696V9.5H17.5696V11.5Z" fill="currentColor"/>
    </template>
    <!-- 3 = hit -->
    <template v-if="type == 3">
      <path d="M10.5698 18.5C6.15982 18.5 2.56982 14.91 2.56982 10.5C2.56982 6.09 6.15982 2.5 10.5698 2.5C14.9798 2.5 18.5698 6.09 18.5698 10.5C18.5698 14.91 14.9798 18.5 10.5698 18.5ZM10.5698 0.5C9.25661 0.5 7.95625 0.758658 6.74299 1.2612C5.52973 1.76375 4.42734 2.50035 3.49876 3.42893C1.62339 5.3043 0.569824 7.84784 0.569824 10.5C0.569824 13.1522 1.62339 15.6957 3.49876 17.5711C4.42734 18.4997 5.52973 19.2362 6.74299 19.7388C7.95625 20.2413 9.25661 20.5 10.5698 20.5C13.222 20.5 15.7655 19.4464 17.6409 17.5711C19.5163 15.6957 20.5698 13.1522 20.5698 10.5C20.5698 9.18678 20.3112 7.88642 19.8086 6.67317C19.3061 5.45991 18.5695 4.35752 17.6409 3.42893C16.7123 2.50035 15.6099 1.76375 14.3967 1.2612C13.1834 0.758658 11.883 0.5 10.5698 0.5V0.5ZM11.5698 5.5H9.56982V9.5H5.56982V11.5H9.56982V15.5H11.5698V11.5H15.5698V9.5H11.5698V5.5Z" fill="currentColor"/>
    </template>
    <!-- 4 = error help -->
    <template v-if="type == 4">
      <path d="M10.8851 8.02344C9.87038 9.3219 9.36304 11.0417 9.36304 13.1829V13.3635C9.36304 15.496 9.87038 17.2245 10.8851 18.5487C11.9084 19.873 13.2283 20.5351 14.845 20.5351C16.5476 20.5351 17.8761 19.9504 18.8306 18.7809L18.9467 20.2771H21.1395V0.464844H18.7532V7.73967C17.7987 6.62179 16.5046 6.06285 14.8708 6.06285C13.2283 6.06285 11.8998 6.71638 10.8851 8.02344ZM12.7296 17.1686C12.076 16.2743 11.7493 15.036 11.7493 13.4538C11.7493 11.6824 12.076 10.3452 12.7296 9.44229C13.3831 8.53938 14.2946 8.08793 15.4641 8.08793C16.9431 8.08793 18.0395 8.74576 18.7532 10.0614V16.472C18.0567 17.8307 16.9517 18.51 15.4383 18.51C14.286 18.51 13.3831 18.0629 12.7296 17.1686Z" fill="currentColor"/>
      <path d="M0 14.3267H7.54919V12.3267H0V14.3267Z" fill="currentColor"/>
    </template>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number, Boolean],
      default: '21',
    },
    type: {
      type: [String, Number],
      default: '1',
    },
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      colors: {
        1: 'red--text',
        2: 'green--text',
        3: 'blue--text',
        4: 'amber--text',
      }
    }
  },
  computed: {
    classes() {
      return this.value == this.type ? this.colors[this.type] : 'grey--text';
    },
  },
}
</script>