import utils from '@/services/utils';

export default {
  setAuth(state, data) {
    state.auth = data;
  },
  unsetAuth(state) {
    state.auth = {};
  },

  setProfile(state, payload) {
    state.auth.user = payload;
  },

  startAttendance(state, id) {
    const permit = utils.isNumber(id) && utils.isEmptyOrNone(state.attendances, {id});

    if (permit) {
      state.attendances.push({
        id, programs: [], comments: null, start: utils.now().format(),
      });
    }
  },
  discartAttendance(state, data) {
    state.attendances = utils.reject(state.attendances, data);
  },
  updateAttendance(state, data) {
    state.attendances.map(attendance => {
      if (attendance.id == data.id) {
        attendance = data.attendance;
      }
    });
  },
  toggleAttendanceProgram(state, data) {
    state.attendances.map(attendance => {
      if (attendance.id == data.id) {
        attendance.programs = utils.xorBy(attendance.programs, [data.program], 'id');
      }
    });
  },
  
  setResources(state, data) {
    state.fases = data.fases;
    state.helps = data.helps;
    state.places = data.places;
  },

  setFases(state, data) {
    state.fases = data;
  },
  setHelps(state, data) {
    state.helps = data;
  },
  setPlaces(state, data) {
    state.places = data;
  },
}