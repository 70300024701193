<template>
  <v-text-field dense outlined hide-details flat solo class="v-number-field" type="number" :min="min" :max="max" :value="value" @input="input">
    <template v-slot:prepend-inner>
      <v-icon @click="decrement">mdi-chevron-down</v-icon>
    </template>
    <template v-slot:append>
      <v-icon @click="increment">mdi-chevron-up</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
  },
  methods: {
    decrement() {
      if (this.$utils.isNumber(this.min)) {
        return this.$emit('input', Math.max(this.value - 1, this.min));
      }

      this.$emit('input', this.value);
    },
    increment() {
      if (this.$utils.isNumber(this.max)) {
        return this.$emit('input', Math.min(this.value + 1, this.max));
      }

      this.$emit('input', this.value);
    },
    input(value) {
      this.$emit('input', this.$utils.toInteger(value));
    },
  },
  computed: {
    valueInt() {
      return this.$utils.toInteger(this.value);
    },
  },
}
</script>

<style lang="scss">
  .v-number-field {
    .v-text-field__slot input {
      text-align: center !important;
    }
  }
</style>