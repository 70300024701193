<template>
  <v-dialog persistent max-width="320" v-model="open">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-row dense class="px-2 pb-4">
          <template v-if="cancel.show">
            <v-col cols="auto" class="mr-auto">
              <v-btn text color="primary" @click="cancel.action">
                {{ cancel.label }}
              </v-btn>
            </v-col>
          </template>
          <v-col cols="auto" class="ml-auto">
            <v-btn depressed color="primary" @click="confirm.action">
              {{ confirm.label }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      title: null,
      text: null,
      cancel: {
        label: null,
        action: null,
        show: true,
      },
      confirm: {
        label: null,
        action: null,
        show: true,
      },
    }
  },
  mounted() {
    this.$root.$on('confirm', data => {
      this.open = this.get(data, 'open', true);
      this.title = this.get(data, 'title', null);
      this.text = this.get(data, 'text', null);
      this.cancel = {
        label: this.get(data, 'cancel.label', 'Cancelar'),
        show: this.get(data, 'cancel.show', true),
        action: () => {
          this.open = false;
          if (this.get(data, 'cancel.action', false)) {
            data.cancel.action();
          }
        },
      };
      
      this.confirm = {
        label: this.get(data, 'confirm.label', 'Confirmar'),
        show: this.get(data, 'confirm.show', true),
        action: () => {
          this.open = false;
          if (this.get(data, 'confirm.action', false)) {
            data.confirm.action();
          }
        },
      };
    });
  },
  methods: {
    get(data, item, defaults) {
      return this.$utils.get(data, item, defaults);
    }
  }
}
</script>