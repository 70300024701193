<template>
  <span class="block red--text mt-2 caption" v-if="has">
    {{ message }}
  </span>
</template>

<script>
export default {
  props: {
    form: Object,
    name: String,
  },
  computed: {
    has() {
      return this.errors.has(this.name);
    },
    message() {
      return this.errors.get(this.name);
    },
    errors() {
      return this.form.errors();
    },
  },
}
</script>