<template>
  <v-card min-height="74.5vh" v-bind="$attrs">
    <template v-if="loading">
      <v-overlay absolute opacity="0">
        <v-progress-circular indeterminate color="primary" size="32" />
      </v-overlay>
    </template>
    <template v-else>
      <slot />
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>
